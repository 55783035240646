import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { enums } from '../../enums/enums';
import { environment } from '../../environments/environment';
import { Cert_CourseCreditHours } from '../_models/Cert_CourseCreditHours';
import { PaginationResponseDetails, PaginationResponseModel } from '../_models/pagination-response.model';

@Injectable()
export class CertCourseCreditHoursService {

  constructor(private http: HttpClient, private translate: TranslateService) { }

  private entityName: string = "Cert_CourseCreditHours";

  getCert_CourseCreditHours(page?: PageEvent, ClassId?: string, SchoolYearId?: string, CourseId?: string,): Observable<PaginationResponseModel> {
    let requestUrl = environment.apiUrl + this.entityName + "/GetCert_CourseCreditHours/";

    const queryParams = [];

    queryParams.push('?0=0');

    //Add pagination params if exist.
    if (page && page.pageIndex != null && page.pageSize != null) {
      queryParams.push("&page=" + page.pageIndex)
      queryParams.push("&pageSize=" + page.pageSize)
    }

    if (ClassId && ClassId != "0") queryParams.push("&ClassId=" + ClassId);
    if (SchoolYearId && SchoolYearId != "0") queryParams.push("&SchoolYearId=" + SchoolYearId);
    if (CourseId && CourseId != "0") queryParams.push("&CourseId=" + CourseId);


    return this.http.get<PaginationResponseModel>(requestUrl + queryParams.join('')).pipe(
      map((response: PaginationResponseModel) => {

        if (response.pagingDetails == undefined) {
          let tempPagination: PaginationResponseModel = new PaginationResponseModel();
          tempPagination.isSinglResult = true;
          tempPagination.results = response;

          let tempPaginationDetail: PaginationResponseDetails = new PaginationResponseDetails();
          tempPaginationDetail.totalRecordsCount = tempPagination.results.length;

          tempPagination.pagingDetails = tempPaginationDetail;

          return tempPagination;
        }

        return response;
      }),
      map(res => {

        const lang = this.translate.currentLang;

        res.results.forEach(element => {

          element.displaySchoolYearName = enums.langs.ar == lang ? element.schoolYearTitleAr : element.schoolYearTitle;
          element.displayGradeName = enums.langs.ar == lang ? element.gradeNameAr : element.gradeName;
          element.displayCourseName = enums.langs.ar == lang ? element.courseNameAr : element.courseName;
          element.displayTrackName = enums.langs.ar == lang ? element.trackNameAr : element.trackName;



        });


        return res;
      })
    );
  }

  getCert_CourseCreditHoursWithoutPagination(ClassId?: string, SchoolYearId?: string): Observable<Cert_CourseCreditHours[]> {
    let requestUrl = environment.apiUrl + this.entityName + "/GetCert_CourseCreditHoursWithoutPagination/";

    const queryParams = [];

    queryParams.push('?0=0');

    if (ClassId && ClassId != "0") queryParams.push("&ClassId=" + ClassId);
    if (SchoolYearId && SchoolYearId != "0") queryParams.push("&SchoolYearId=" + SchoolYearId);


    return this.http.get<Cert_CourseCreditHours[]>(requestUrl + queryParams.join(''))
  }


  getCert_CourseCreditHoursById(id: number): Observable<Cert_CourseCreditHours> {
    const requestUrl =
      environment.apiUrl + this.entityName + "/GetCert_CourseCreditHoursById/" + id;
    return this.http.get<Cert_CourseCreditHours>(requestUrl).pipe(
      map(element => {

        const lang = this.translate.currentLang;

        element.displaySchoolYearName = enums.langs.ar == lang ? element.schoolYearTitleAr : element.schoolYearTitle;
        element.displayGradeName = enums.langs.ar == lang ? element.gradeNameAr : element.gradeName;
        element.displayCourseName = enums.langs.ar == lang ? element.courseNameAr : element.courseName;
        element.displayTrackName = enums.langs.ar == lang ? element.trackNameAr : element.trackName;





        return element;
      })
    )
  }


  putCert_CourseCreditHours(value: Cert_CourseCreditHours): Observable<Cert_CourseCreditHours> {
    const requestUrl =
      environment.apiUrl + this.entityName + "/PutCert_CourseCreditHours/";
    return this.http.post<Cert_CourseCreditHours>(requestUrl, value);
  }

  deleteCert_CourseCreditHours(value: Cert_CourseCreditHours): Observable<Cert_CourseCreditHours> {
    const requestUrl =
      environment.apiUrl + this.entityName + "/DeleteCert_CourseCreditHours";
    return this.http.post<Cert_CourseCreditHours>(requestUrl, value);
  }
}
