import { BoxGradeDTO } from "./box-grade-dto.model";

export class BoxTeacherSectionDTO {
    sectionId: number;
    sectionName: string;
    sectionNameAr: string;
    grades: BoxGradeDTO[];

    //UnMapped
    displayName: string;
}