import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { TranslateService } from "@ngx-translate/core";
import { SchoolGradeSectionDtoModel } from "../_models/school-grade-section-dto.model";
import { SchoolGradeUsersDtoModel } from "../_models/school-grade-users-dto.model";
import { BoxGradeSectionCourseForPost } from "../_models/box-grade-section-school-dto.model";
import { SchoolGradeCourseDtoModel } from "../_models/school-grade-course-dto.model";
import { SchoolGradeTeacherCourseDtoModel } from "../_models/school-grade-teacher-course-dto.model";

@Injectable()
export class BoxSchoolGradeBySectionService {
	constructor(
		private http: HttpClient,
		private translate: TranslateService
	) { }

	private entityName: string = "Box_SchoolGradeBySection";

	deActivateGradesCourse(gradeCourseID?: number): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/DeActivateGradesCourse/";
		let queryStringParams: string[] = [];

		if (gradeCourseID)
			queryStringParams.push("?gradeCourseID=" + gradeCourseID);

		return this.http.post<any>(requestUrl + queryStringParams.join(''), null);
	}

	deActivateGradesTeacherCourse(gradeTeacherID?: number): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/DeActivateGradesTeacherCourse/";
		let queryStringParams: string[] = [];

		if (gradeTeacherID)
			queryStringParams.push("?gradeTeacherID=" + gradeTeacherID);

		return this.http.post<any>(requestUrl + queryStringParams.join(''), null);
	}

	deActivateGradesUser(gradeUserID?: number): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/DeActivateGradesUser/";
		let queryStringParams: string[] = [];

		if (gradeUserID)
			queryStringParams.push("?gradeUserID=" + gradeUserID);

		return this.http.post<any>(requestUrl + queryStringParams.join(''), null);
	}

	deActivateSchoolGradeBySection(schoolGradeSectionId?: number): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/DeActivateSchoolGradeBySection/";
		let queryStringParams: string[] = [];
		queryStringParams.push("?1=1");

		if (schoolGradeSectionId)
			queryStringParams.push("&schoolGradeSectionId=" + schoolGradeSectionId);

		return this.http.post<any>(requestUrl + queryStringParams.join(''), null);
	}

	DeActivateMultipleGradesUser(usersIds: number[]): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/DeActivateMultipleGradesUser/";
		return this.http.post<any>(requestUrl, usersIds);
	}

	postSchoolGradeBySection(value: SchoolGradeSectionDtoModel): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PostSchoolGradeBySection/";
		return this.http.post<any>(requestUrl, value);
	}

	PutGradeSectionByUser(value: BoxGradeSectionCourseForPost): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PutGradeSectionByUser/";
		return this.http.post<any>(requestUrl, value);
	}

	postGradesUsers(values: SchoolGradeUsersDtoModel[]): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PostGradesUsers/";
		return this.http.post<any>(requestUrl, values);
	}

	postGradesCourses(values: SchoolGradeCourseDtoModel[]): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PostGradesCourses/";
		return this.http.post<any>(requestUrl, values);
	}

	postGradesTeacherCourse(values: SchoolGradeTeacherCourseDtoModel[]): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PostGradesTeacherCourse/";
		return this.http.post<any>(requestUrl, values);
	}

	upgradeStudents(fromSchoolYearID: number, toSchoolYearID: number, branchID: number): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/UpgradeStudents/";
		let queryStringParams: string[] = [];

		queryStringParams.push("?fromSchoolYearID=" + fromSchoolYearID);
		queryStringParams.push("&toSchoolYearID=" + toSchoolYearID);
		queryStringParams.push("&branchID=" + branchID);

		return this.http.post<any>(requestUrl + queryStringParams.join(''), null);
	}
}
