export class OutcomeModel {
    outcomeId: number;
    title: string;
    titleAr: string;
    lessonId: number;
    isAddedByTeacher: boolean;
    isActive: boolean;
    isDeleted: boolean;
    createdBy: string;
    createdDate: string;
    lessonName: string;
    lessonNameAr: string;


    courseId: number;
    courseName: string;
    courseNameAr: string;
    displayCourseName: string;

    gradeId: number;
    gradeName: string;
    gradeNameAr: string;
    displayGradeName: string;

    learningTrackId: number;
    learningTrackName: string;
    learningTrackNameAr: string;
    displayTrackName: string;

    unitId: number;
    unitName: string;
    unitNameAr: string;
    displayUnitName: string;


    displayName: string;
    courseLessonDisplayTitle: string;
    creatorName: string;
    isChecked: boolean;
}


