import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { enums } from '../../enums/enums';
import { environment } from '../../environments/environment';
import { CertificateGradeOption } from '../_models/certificate-grade-options.model';
import { PaginationResponseModel, PaginationResponseDetails } from '../_models/pagination-response.model';

@Injectable()
export class CertificateGradeOptionsService {

  constructor(private http: HttpClient, private translate: TranslateService) { }

  private entityName: string = "Cert_GradeOptions";

  getCert_GradeOptions(page?: PageEvent, SchoolYearId?: string): Observable<PaginationResponseModel> {
    let requestUrl = environment.apiUrl + this.entityName + "/GetCert_GradeOptions/";

    const queryParams = [];

    queryParams.push('?0=0');

    //Add pagination params if exist.
    if (page && page.pageIndex != null && page.pageSize != null) {
      queryParams.push("&page=" + page.pageIndex)
      queryParams.push("&pageSize=" + page.pageSize)
    }

    if (SchoolYearId && SchoolYearId != "0") queryParams.push("&SchoolYearId=" + SchoolYearId);

    return this.http.get<PaginationResponseModel>(requestUrl + queryParams.join('')).pipe(
      map((response: PaginationResponseModel) => {

        if (response.pagingDetails == undefined) {
          let tempPagination: PaginationResponseModel = new PaginationResponseModel();
          tempPagination.isSinglResult = true;
          tempPagination.results = response;

          let tempPaginationDetail: PaginationResponseDetails = new PaginationResponseDetails();
          tempPaginationDetail.totalRecordsCount = tempPagination.results.length;

          tempPagination.pagingDetails = tempPaginationDetail;

          return tempPagination;
        }

        return response;
      }),
      map(res => {

        const lang = this.translate.currentLang;

        res.results.forEach(element => {

          element.displaySchoolYearName = enums.langs.ar == lang ? element.schoolYearTitleAr : element.schoolYearTitle;

        });


        return res;
      })
    );
  }



  getCert_GradeOptionsById(id: number): Observable<CertificateGradeOption> {
    const requestUrl =
      environment.apiUrl + this.entityName + "/GetCert_GradeOptionsById/" + id;
    return this.http.get<CertificateGradeOption>(requestUrl).pipe(
      map(element => {

        const lang = this.translate.currentLang;

        element.displaySchoolYearName = enums.langs.ar == lang ? element.schoolYearTitleAr : element.schoolYearTitle;

        return element;
      })
    )
  }


  putCert_GradeOptions(value: CertificateGradeOption): Observable<CertificateGradeOption> {
    const requestUrl =
      environment.apiUrl + this.entityName + "/PutCert_GradeOptions/";
    return this.http.post<CertificateGradeOption>(requestUrl, value);
  }

  deleteCert_GradeOptions(value: CertificateGradeOption): Observable<CertificateGradeOption> {
    const requestUrl =
      environment.apiUrl + this.entityName + "/DeleteCert_GradeOptions";
    return this.http.post<CertificateGradeOption>(requestUrl, value);
  }
}
