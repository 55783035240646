export class StudentEducatorsModel {
	constructor(_studentId?: string, _studentName?: string) {
		this.studentId = _studentId;
		this.studentName = _studentName;
	}
	id: number;
	studentId: string;
	groupId: number;
	createdOn: string;

	studentName: string;
	groupName: string;
}
