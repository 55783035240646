export class GradeModel {
    gradeId: number;
    gradeName: string;
    gradeNameAr: string;
    stageId: number;
    isActive: boolean;
    isDeleted: boolean;
    createdBy: string;
    createdDate: string;
    gradeOrder: number;

    //Extend Fields
    displayName: string;
    displayStageName: string;
    stageColor: string;

    testDragObj: any;
}

export class GradeOrderModel {
    gradeId: number;
    grade_Order: number;
}
