import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { enums } from '../../enums/enums';
import { environment } from '../../environments/environment';
import { BoxUserCourse } from '../_models/box-user-course.model';

@Injectable()
export class BoxUserCourseService {

  constructor(private http: HttpClient, private translate: TranslateService) { }

    private entityName: string = 'BoxUserCourse';

    getBoxUserCourseByUserId(userId: number): Observable<BoxUserCourse[]> {
      const requestUrl = environment.apiUrl + this.entityName + '/GetBoxUserCoursesByUserId/' + userId;
      return this.http.get<BoxUserCourse[]>(requestUrl)
          .pipe(map((serviceResponse: BoxUserCourse[]) => {

              serviceResponse.forEach(element => {
                  element.displayCourseName = this.translate.currentLang == enums.langs.ar ? element.courseNameAr : element.courseName;
                  
              });

              return serviceResponse;
          }));
  }


  postBoxUserCourse(userCourse: BoxUserCourse): Observable<BoxUserCourse> {
    const requestUrl = environment.apiUrl + this.entityName + '/PostBoxUserCourse';

    return this.http.post<BoxUserCourse>(requestUrl , userCourse);
}


deleteBoxUserCourse(userID: number , courseID : number): Observable<BoxUserCourse> {
  const requestUrl = environment.apiUrl + this.entityName + `/DeleteBoxUserCourse?userID=${userID}&courseID=${courseID}` ;

  return this.http.get<BoxUserCourse>(requestUrl);
}


}
