export class CourseTraineeLectureModel {
    id: number;
    classRoom: string;
    trainingProgramId: number;
    startOn: string;
    endOn: string;
    place: string;
    groupNo: number;

    programName: string;
    instructorName: string;
}