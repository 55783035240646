import { BoxSchoolGradesBySectionModel } from "./box-school-grades-by-section.model";

export class GradeSectionModel {
    sectionId: number;
    sectionName: string;
    sectionNameAr: string;
    isDeleted: boolean;
    boxSchoolGradesBySection: Array<BoxSchoolGradesBySectionModel>

    //Extend Fields
    displayName: string;
    displayGradeName: string;
}
