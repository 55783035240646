import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { CertInternationalStudentModel } from '../../_models/cert/cert-international-student.model';
import { TranslateService } from "@ngx-translate/core";
import { enums } from "../../../enums/enums";

@Injectable()
export class CertInternationalStudentService {
    constructor(private http: HttpClient, private router: Router) { }

    private entityName: string = 'Cert_InternationalStudents';

    getCertInternationalStudents(): Observable<CertInternationalStudentModel[]> {
        const requestUrl = environment.apiUrl + this.entityName + '/GetCert_InternationalStudents/';
        return this.http.get<CertInternationalStudentModel[]>(requestUrl);
    }

    getFilteredCertInternationalStudents(schoolId: string, gradeId?: string): Observable<CertInternationalStudentModel[]> {

        let query = `?schoolId=${schoolId}`;

        if (gradeId) query = query + `&gradeId=${gradeId}`

        const requestUrl = environment.apiUrl + this.entityName + '/GetFilteredCert_InternationalStudents' + query;
        return this.http.get<CertInternationalStudentModel[]>(requestUrl);
    }

    getCertInternationalStudent(userId: number): Observable<CertInternationalStudentModel> {
        const requestUrl = environment.apiUrl + this.entityName + '/GetCert_InternationalStudent/' + userId;
        return this.http.get<CertInternationalStudentModel>(requestUrl);
    }

    postCertInternationalStudent(value: CertInternationalStudentModel): Observable<any> {
        const requestUrl = environment.apiUrl + this.entityName + '/PostCert_InternationalStudent/';
        return this.http.post<any>(requestUrl, value);
    }

    putCertInternationalStudent(value: CertInternationalStudentModel): Observable<any> {
        const requestUrl = environment.apiUrl + this.entityName + '/PutCert_InternationalStudent';
        return this.http.post<any>(requestUrl, value);
    }

    deleteCertInternationalStudent(userId: string): Observable<any> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?userId=" + userId);

        const requestUrl = environment.apiUrl + this.entityName + '/DeleteCert_InternationalStudent/' + + queryStringParams.join('');;
        return this.http.get<any>(requestUrl);
    }
}
