export class TrainingProgramModel {
    id: number;
    title: string;
    gender: boolean;
    type: number;
    numberOfTarget: number;
    schoolYearId: number;
    term: number;
    createdOn: string;
    createdBy: string;
    place: string;
    notes: string;
    status: number;
    instructorId: string;
    instructorName: string;
    schoolYearName: string;
}