import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ApplicationLogsModel } from "../_models/applicationLogs.model";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { enums } from "../../enums/enums";
import { PageEvent } from "@angular/material";
import { PaginationResponseModel } from "../_models/pagination-response.model";

@Injectable()
export class ApplicationLogsService {
	constructor(private http: HttpClient, private router: Router) { }

	private entityName: string = "ApplicationLogs";

	getApplicationLogs(page?: PageEvent): Observable<PaginationResponseModel> {
		let requestUrl = environment.apiUrl + this.entityName + "/GetApplicationLogs/";

		//Add pagination params if exist.
		if (page && page.pageIndex != null && page.pageSize != null) {
			requestUrl += "?page=" + page.pageIndex;
			requestUrl += "&pageSize=" + page.pageSize;
		}

		return this.http.get<PaginationResponseModel>(requestUrl);
	}

	getApplicationLog(id: number): Observable<ApplicationLogsModel> {
		const requestUrl =
			environment.apiUrl +
			this.entityName +
			"/GetApplicationLogs/" +
			id;
		return this.http.get<ApplicationLogsModel>(requestUrl);
	}

	postApplicationLogs(value: ApplicationLogsModel): Observable<any> {
		const requestUrl =
			environment.apiUrl + this.entityName + "/PostApplicationLogs/";
		return this.http.post<any>(requestUrl, value);
	}

	putApplicationLogs(value: ApplicationLogsModel): Observable<any> {
		const requestUrl =
			environment.apiUrl + this.entityName + "/PutApplicationLogs";
		return this.http.post<any>(requestUrl, value);
	}

	deleteApplicationLogs(id: number): Observable<any> {
		const requestUrl =
			environment.apiUrl +
			this.entityName +
			"/DeleteApplicationLogs/" +
			id;
		return this.http.get<any>(requestUrl);
	}

	getApplicationLogsModel(
		_loggedUser,
		_actionTaken,
		_category,
		_referenceID
	) {
		let _applicationLogsModel = new ApplicationLogsModel();
		_applicationLogsModel.logID = 0;
		_applicationLogsModel.userId = _loggedUser;
		_applicationLogsModel.actionTaken = _actionTaken;
		_applicationLogsModel.referenceID = _referenceID;
		_applicationLogsModel.category = _category;
		_applicationLogsModel.actionDate = new Date().toJSON();

		this.postApplicationLogs(_applicationLogsModel).subscribe((data) => {
		});

		return _applicationLogsModel;
	}
}
