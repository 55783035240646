import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { WFStepsModel } from "../_models/wf-steps.model";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { enums } from "../../enums/enums";

@Injectable()
export class WFStepsService {
    constructor(private http: HttpClient, private translate: TranslateService) { }

    private entityName: string = "WFSteps";

    getWFSteps(): Observable<WFStepsModel[]> {
        const requestUrl =
            environment.apiUrl + this.entityName + "/GetWFSteps";
        return this.http.get<WFStepsModel[]>(requestUrl);
    }

    getWFStep(id: number): Observable<WFStepsModel> {
        const requestUrl =
            environment.apiUrl + this.entityName + "/GetWFSteps/" + id;
        return this.http.get<WFStepsModel>(requestUrl);
    }

    putWFSteps(value: WFStepsModel): Observable<any> {
        const requestUrl =
            environment.apiUrl + this.entityName + "/PutWFSteps";
        return this.http.post<any>(requestUrl, value);
    }

    postWFSteps(value: Array<WFStepsModel>): Observable<any> {
        const requestUrl =
            environment.apiUrl + this.entityName + "/PostWFSteps";
        return this.http.post<any>(requestUrl, value);
    }
    deleteWFSteps(value: Array<WFStepsModel>): Observable<any> {
        const requestUrl =
            environment.apiUrl + this.entityName + "/DeleteWFSteps";
        return this.http.post<any>(requestUrl, value);
    }

    getStepsByTypeId(typeId: number): Observable<WFStepsModel[]> {
        const requestUrl = environment.apiUrl + this.entityName + "/GetWFStepsByTypeId?typeId=" + typeId;
        return this.http.get<WFStepsModel[]>(requestUrl)
            .pipe(map((serviceResponse: WFStepsModel[]) => {

                serviceResponse.forEach(element => {
                    element.displayName = this.translate.currentLang == enums.langs.ar ? element.stepNameAr : element.stepName;
                });

                return serviceResponse;
            }));
    }
}
