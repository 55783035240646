import { BoxTeacherSectionDTO } from "./box-teacher-section-dto.model";

export class BoxTeacherSchoolDTO {
    schoolId: number;
    schoolName: string;
    schoolNameAr: string;
    trackID: number;
    trackName: string;
    trackNameAr: string;
    trackColor: string;
    sections: BoxTeacherSectionDTO[];

    //UnMapped
    displayName: string;
}