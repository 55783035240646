export class BranchSchoolModel {
    //Branch School Id
    id: number;
    schoolCode: string;
    schoolSecondCode: string;

    //BranchId
    branchId: number;

    schoolLevelId: number;
    eduTypeId: number;
    schoolIcon: string;
    schoolManager: string;
    notes: string;
    color: string;
    gender: boolean;
    licenseNumber: string;
    managementLeader: string;
    academicSupervisor: string;
    examControl: string;
    schoolPlanId: number;

    administration: string;
    office: string;
    administrationAr: string;
    officeAr: string;
    logo: string;

    managerFullName: string;

    trackName: string;
    trackNameAr: string;
    displayTrackName: string;

    branchNameAr: string;
    branchName: string;
    displayBranchName: string;

    stageName: string;
    stageNameAr: string;
    displayStageName: string;

    schoolName: string;
    schoolNameEn: string;
    displaySchoolName: string;

    schoolPlanName: string;
    schoolPlanNameAr: string;
    displaySchoolPlanName: string;
}