export class GradeByCourseModel {
    gradeByCourseId: number;
    courseId: number;
    gradeId: number;
    isActive: boolean;
    isDeleted: boolean;
    createdBy: string;
    createdDate: string;

    courseName: string;
    courseNameAr: string;
    gradeName: string;
    gradeNameAr: string;

    //Extend Fields
    displayNameOfCourse: string;
    displayNameOfGrade: string;
}
