import { StudentDetails } from "../student-details.model";
import { UserModel } from "../user.model";

export class CertInternationalStudentModel {
    userId: string;
    birthPlaceCityEN: string;
    admissionDate: string;
    previousSchoolEN: string;
    languageENId: string;
    fNameEN: string;
    sNameEN: string;
    mNameEN: string;
    familyEN: string;
    addressEN: string;

    userObj: UserModel;
    studentDetails: StudentDetails;
}