import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { enums } from '../../enums/enums';
import { environment } from '../../environments/environment';
import { Job } from '../_models/job.model';

@Injectable()
export class JobsService {

  constructor(private http: HttpClient, private translate: TranslateService) { }

    private entityName: string = 'jobs';


    getJobs() {
      const requestUrl = environment.apiUrl + this.entityName + '/getJobs/';
        return this.http.get<Job[]>(requestUrl).pipe(map((serviceResponse: Job[]) => {
            serviceResponse.forEach(element => {
                element.displayTitle = (this.translate.currentLang == enums.langs.ar ? element.titleAr : element.titleEn);
            });

            return serviceResponse;
        }));
    }

    putJob(job: Job) {
      const requestUrl = environment.apiUrl + this.entityName + '/putJob/';
        return this.http.post<Job>(requestUrl , job );
    }

    deleteJob(id: number) {
      const requestUrl = environment.apiUrl + this.entityName + '/deleteJob/' + id;
        return this.http.get<Job>(requestUrl );
    }

}
