import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { CourseTraineeLectureModel } from '../_models/course-trainee-lecture.model';
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { enums } from "../../enums/enums";

@Injectable()
export class CourseTraineeLectureService {
    constructor(private http: HttpClient, private router: Router) { }

    private entityName: string = 'TrainingProgramCourseTraineeLecture';

    getTrainingProgramCourseTraineeLectureByTrainingProgram(trainingProgramId: number): Observable<CourseTraineeLectureModel[]> {
        let queryStringParams: string[] = [];

        if (trainingProgramId)
            queryStringParams.push("?trainingProgramId=" + trainingProgramId);

        const requestUrl = environment.apiUrl + this.entityName + '/GetTrainingProgramCourseTraineeLectureByTrainingProgram/' + queryStringParams.join('');
        return this.http.get<CourseTraineeLectureModel[]>(requestUrl);
    }

    getTrainingProgramCourseTraineeLecture(id: number): Observable<CourseTraineeLectureModel> {
        const requestUrl = environment.apiUrl + this.entityName + '/GetTrainingProgramCourseTraineeLecture/' + id;
        return this.http.get<CourseTraineeLectureModel>(requestUrl);
    }

    postTrainingProgramCourseTraineeLecture(value: CourseTraineeLectureModel): Observable<any> {
        const requestUrl = environment.apiUrl + this.entityName + '/PostTrainingProgramCourseTraineeLecture/';
        return this.http.post<any>(requestUrl, value);
    }

    putTrainingProgramCourseTraineeLecture(value: CourseTraineeLectureModel): Observable<any> {
        const requestUrl = environment.apiUrl + this.entityName + '/PutTrainingProgramCourseTraineeLecture';
        return this.http.post<any>(requestUrl, value);
    }

    deleteTrainingProgramCourseTraineeLecture(id: number): Observable<any> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?id=" + id);

        const requestUrl = environment.apiUrl + this.entityName + '/DeleteTrainingProgramCourseTraineeLecture/' + queryStringParams.join('');
        return this.http.get<any>(requestUrl);
    }
}
