import { BoxUserCourses } from "./box-user-courses.model";

export class BoxGradeDTO {
    gradeId: number;
    gradeName: string;
    gradeNameAr: string;
    stageId: number;
    isActive: boolean;
    isDeleted: boolean;
    createdBy: string;
    createdDate: string;
    courses: BoxUserCourses[];

    //UnMapped
    displayName: string;
}