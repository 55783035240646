export class CourseUnitModel {
    unitId: number;
    unitName: string;
    unitNameAr: string;
    courseId: number;
    trackId: number;
    gradeId: number;
    term: number;
    isAddedByTeacher: boolean;
    unitDescription: string;
    isActive: boolean;
    isDeleted: boolean;
    createdBy: string;
    createdDate: string;

    gradeName: string;
    gradeNameAr: string;
    trackName: string;
    trackNameAr: string;
    courseName: string;
    courseNameAr: string;

    displayName: string;
    trackDisplayName: string;
    courseDisplayName: string;
    gradeDisplayName: string;
}

