import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { WFTypesModel } from "../_models/wf-types.model";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { enums } from "../../enums/enums";

@Injectable()
export class WFTypesService {
    constructor(private http: HttpClient, private translate: TranslateService) { }

    private entityName: string = "WFTypes";

    getWFTypes(): Observable<WFTypesModel[]> {
        const requestUrl =
            environment.apiUrl + this.entityName + "/GetWFType";
        return this.http.get<WFTypesModel[]>(requestUrl)
            .pipe(map((serviceResponse: WFTypesModel[]) => {

                serviceResponse.forEach(element => {
                    element.displayName = this.translate.currentLang == enums.langs.ar ? element.typeNameAr : element.typeName;
                });

                return serviceResponse;
            }));
    }

    getWFType(id: number): Observable<WFTypesModel> {
        const requestUrl =
            environment.apiUrl + this.entityName + "/GetWFType/" + id;
        return this.http.get<WFTypesModel>(requestUrl);
    }

    putWFType(value: WFTypesModel): Observable<any> {
        const requestUrl =
            environment.apiUrl + this.entityName + "/PutWFType";
        return this.http.post<any>(requestUrl, value);
    }

    postWFType(value: WFTypesModel): Observable<any> {
        const requestUrl =
            environment.apiUrl + this.entityName + "/PostWFType";
        return this.http.post<any>(requestUrl, value);
    }

    deleteWFType(id: number): Observable<any> {
        const requestUrl =
            environment.apiUrl + this.entityName + "/DeleteWFType?id=" + id;
        return this.http.get<any>(requestUrl);
    }
}
