import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { NeedPlanModel } from '../_models/need-plan.model';

@Injectable()
export class NeedPlanService {
	constructor(private http: HttpClient, private router: Router) { }

	private entityName: string = 'SNSplans';

	getNeedPlans(): Observable<NeedPlanModel[]> {
		const requestUrl = environment.apiUrl + this.entityName + '/GetSNSPlans/';
		return this.http.get<NeedPlanModel[]>(requestUrl);
	}

	getOneNeedPlan(id: number, onlyUserSchools?: boolean, userId?: number): Observable<NeedPlanModel[]> {
		const requestUrl = environment.apiUrl + this.entityName + `/GetOneNeedPlan/${id}?userId=${userId || ''}&onlyUserSchools=${onlyUserSchools || ''}`;
		return this.http.get<NeedPlanModel[]>(requestUrl);
	}

	getSchNeedPlans(schid: number): Observable<NeedPlanModel[]> {
		const requestUrl = environment.apiUrl + this.entityName + '/GetSchNeedPlans/' + schid;
		return this.http.get<NeedPlanModel[]>(requestUrl);
	}

	getNeedPlan(id: number): Observable<NeedPlanModel> {
		const requestUrl = environment.apiUrl + this.entityName + '/GetNeedPlan/' + id;
		return this.http.get<NeedPlanModel>(requestUrl);
	}

	postNeedPlan(value: NeedPlanModel): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + '/PostNeedPlan/';
		return this.http.post<any>(requestUrl, value);
	}

	putNeedPlans(value: NeedPlanModel): Observable<any> {

		const requestUrl = environment.apiUrl + this.entityName + '/PutNeedPlan';
		return this.http.post<any>(requestUrl, value);
	}

	importPlanCourses(sourceId: number, newId: number, onlyUserSchools?: boolean, userId?: number): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName
			+ `/importPlanCourses?planID=${sourceId || ''}&newPlanID=${newId || ''}&userId=${userId || ''}&onlyUserSchools=${onlyUserSchools || ''}`;
		return this.http.get<any>(requestUrl);
	}

	deleteNeedPlans(id: number): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + '/DeleteNeedPlan/' + id;
		return this.http.get<any>(requestUrl);
	}

}
