import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { CertificateTemplateModel } from '../../_models/cert/certificate-template.model';
import { map } from "rxjs/operators";
import { PaginationResponseDetails, PaginationResponseModel } from '../../../app/_models/pagination-response.model';
import { PageEvent } from '@angular/material';

@Injectable()
export class CertificateTemplateService {
    constructor(private http: HttpClient, private router: Router) { }

    private entityName: string = 'TrainingProgramCertificateTemplate';

    getTrainingProgramCertificateTemplates(): Observable<CertificateTemplateModel[]> {
        const requestUrl = environment.apiUrl + this.entityName + '/GetTrainingProgramCertificateTemplates/';
        return this.http.get<CertificateTemplateModel[]>(requestUrl);
    }

    getFilteredTrainingProgramCertificateTemplates(page?: PageEvent, gender?: number, type?: number, title?: string): Observable<PaginationResponseModel> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?1=1");

        if (gender)
            queryStringParams.push("&Gender=" + gender);

        if (type)
            queryStringParams.push("&Type=" + type);

        if (title)
            queryStringParams.push("&Title=" + title);

        //Add pagination params if exist.
        if (page && page.pageIndex != null && page.pageSize != null) {
            queryStringParams.push("&page=" + page.pageIndex);
            queryStringParams.push("&pageSize=" + page.pageSize);
        }

        let requestUrl: string = environment.apiUrl + this.entityName + "/GetFilteredTrainingProgramCertificateTemplates/" + queryStringParams.join('');

        return this.http.get<PaginationResponseModel>(requestUrl).pipe(map((response: PaginationResponseModel) => {

            if (response.pagingDetails == undefined) {
                let tempPagination: PaginationResponseModel = new PaginationResponseModel();
                tempPagination.isSinglResult = true;
                tempPagination.results = response;

                let tempPaginationDetail: PaginationResponseDetails = new PaginationResponseDetails();
                tempPaginationDetail.totalRecordsCount = tempPagination.results.length;

                tempPagination.pagingDetails = tempPaginationDetail;

                return tempPagination;
            }

            return response;
        })
        );
    }

    getTrainingProgramCertificateTemplate(id: number): Observable<CertificateTemplateModel> {
        const requestUrl = environment.apiUrl + this.entityName + '/GetTrainingProgramCertificateTemplate/' + id;
        return this.http.get<CertificateTemplateModel>(requestUrl);
    }

    getTrainingProgramCertificateTemplateByGender(gender: number): Observable<CertificateTemplateModel[]> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?Gender=" + gender);

        const requestUrl = environment.apiUrl + this.entityName + '/getTrainingProgramCertificateTemplateByGender/' + queryStringParams.join('');
        return this.http.get<CertificateTemplateModel[]>(requestUrl);
    }

    postTrainingProgramCertificateTemplate(values: Array<CertificateTemplateModel>): Observable<Array<CertificateTemplateModel>> {
        const requestUrl = environment.apiUrl + this.entityName + '/PostTrainingProgramCertificateTemplate/';
        return this.http.post<Array<CertificateTemplateModel>>(requestUrl, values);
    }

    utTrainingProgramCertificateTemplate(value: CertificateTemplateModel): Observable<any> {
        const requestUrl = environment.apiUrl + this.entityName + '/PutTrainingProgramCertificateTemplate';
        return this.http.post<any>(requestUrl, value);
    }

    deleteTrainingProgramCertificateTemplate(id: number): Observable<any> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?id=" + id);

        const requestUrl = environment.apiUrl + this.entityName + '/DeleteTrainingProgramCertificateTemplate/' + queryStringParams.join('');
        return this.http.get<any>(requestUrl);
    }
}
