import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { EducatorGroupModel } from "../_models/educator-group.model";
import { map } from "rxjs/operators";
import { PaginationResponseDetails, PaginationResponseModel } from "../_models/pagination-response.model";
import { PageEvent } from "@angular/material";

@Injectable()
export class EducatorGroupService {
	constructor(private http: HttpClient, private router: Router) { }

	private entityName: string = "EducatorGroups";

	GetEducatorGroups(teacherId?: string, projectManagerId?: string, schoolId?:number, name?: string, page?: PageEvent): Observable<PaginationResponseModel> {
		let requestUrl: string = environment.apiUrl + this.entityName + "/GetEducatorGroups";

		const queryParams = [];
		queryParams.push("?1=1");

		//Add pagination params if exist.
		if (page && page.pageIndex != null && page.pageSize != null) {
			queryParams.push("&page=" + page.pageIndex)
			queryParams.push("&pageSize=" + page.pageSize)
		}


		if (name) queryParams.push("&name=" + name)
		if (teacherId && teacherId != '0') queryParams.push("&teacherId=" + teacherId)
		if (projectManagerId && projectManagerId != '0') queryParams.push("&projectManagerId=" + projectManagerId)
		if(schoolId) queryParams.push("&schoolId=" + schoolId)

		return this.http.get<PaginationResponseModel>(requestUrl + queryParams.join('')).pipe(map(response => {

			if (response.pagingDetails == undefined) {
				let tempPagination: PaginationResponseModel = new PaginationResponseModel();
				tempPagination.isSinglResult = true;
				tempPagination.results = response;

				let tempPaginationDetail: PaginationResponseDetails = new PaginationResponseDetails();
				tempPaginationDetail.totalRecordsCount = tempPagination.results.length;

				tempPagination.pagingDetails = tempPaginationDetail;


				return tempPagination;
			}


			return response;

		}));

	}


	getEducatorGroupsByTeacherId(teacherId: number): Observable<EducatorGroupModel[]> {
		const requestUrl =
			environment.apiUrl + this.entityName + "/GetEducatorGroupsByTeacherId?teacherId=" + teacherId;
		return this.http.get<EducatorGroupModel[]>(requestUrl);
	}

	getEducatorGroup(id: number): Observable<EducatorGroupModel> {
		const requestUrl =
			environment.apiUrl +
			this.entityName +
			"/GetEducatorGroup/" + id;
		return this.http.get<EducatorGroupModel>(requestUrl);
	}

	GetStudentGroup(id: number): Observable<EducatorGroupModel> {
		const requestUrl =
			environment.apiUrl +
			this.entityName +
			"/GetStudentGroup/" + id;
		return this.http.get<EducatorGroupModel>(requestUrl);
	}

	putEducatorGroup(value: EducatorGroupModel): Observable<any> {
		const requestUrl =
			environment.apiUrl + this.entityName + "/PutEducatorGroup/";
		return this.http.post<any>(requestUrl, value);
	}

	postEducatorGroup(value: EducatorGroupModel): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PostEducatorGroup/";
		return this.http.post<any>(requestUrl, value);
	}

	deleteEducatorGroup(model: EducatorGroupModel): Observable<any> {
		const requestUrl =
			environment.apiUrl + this.entityName + "/DeleteEducatorGroup";
		return this.http.post<any>(requestUrl, model);
	}
}
