import { BoxGradeDTO } from "./box-grade-dto.model";
import { BoxTeacherSchoolDTO } from "./box-teacher-school-dto.model";
import { BoxTeacherSectionDTO } from "./box-teacher-section-dto.model";
import { BoxUserCourses } from "./box-user-courses.model";

export class UserGradeSectionDto {
    
    course: BoxUserCourses;
    grade: BoxGradeDTO;
    school: BoxTeacherSchoolDTO;
    section: BoxTeacherSectionDTO;

    gradeUserId: number;
    schoolGradeSectionId: number;
    gradeTeacherId: number;

    userFullName: string;
}