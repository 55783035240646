export class CourseLessonModel {
    courseLessonId: number;
    title: string;
    titleAr: string;
    unitId: number;
    notes: string;
    isAddedByTeacher: boolean;
    isActive: boolean;
    isDeleted: boolean;
    createdBy: string;
    createdDate: string;
    unitName: string;
    unitNameAr: string;

    displayName: string;
    courseUnitDisplayTitle: string;
}
